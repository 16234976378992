import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Select,
} from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  register: any;
  id: string;
  label: string;
  isInvalid?: any;
  placeholder: string;
  defaultValue?: string;
  optionData: string[];
};

export const FormSelect: FC<Props> = (props) => {
  const {
    register,
    id,
    label,
    isInvalid,
    placeholder,
    defaultValue = undefined,
    optionData,
  } = props;

  return (
    <>
      <FormControl id={id} isInvalid={!!isInvalid}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <Box>
          <Select
            {...register}
            placeholder={placeholder}
            defaultValue={defaultValue}
            variant="flushed"
          >
            {optionData.map((data) => {
              return <option key={data}>{data}</option>;
            })}
          </Select>
          <FormErrorMessage>
            {isInvalid && typeof isInvalid.message === "string"
              ? isInvalid.message
              : ""}
          </FormErrorMessage>
        </Box>
      </FormControl>
    </>
  );
};
