import {
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
} from "@chakra-ui/react";
import { FC, memo } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onClickHome: () => void;
  onClickPlayer: () => void;
  onClickPlayerAdd: () => void;
  onClickPlayerStats: () => void;
  onClickGameResult: () => void;
  onClickGameResultRegister: () => void;
};

export const MenuDrawer: FC<Props> = memo((props) => {
  const {
    isOpen,
    onClose,
    onClickHome,
    onClickPlayer,
    onClickPlayerAdd,
    onClickPlayerStats,
    onClickGameResult,
    onClickGameResultRegister,
  } = props;
  return (
    <Drawer placement="left" size="xs" onClose={onClose} isOpen={isOpen}>
      <DrawerOverlay>
        <DrawerContent>
          <DrawerBody p={0} bg="gray.100">
            <Button onClick={onClickHome} w="100%">
              TOP
            </Button>
            <Button onClick={onClickPlayer} w="100%">
              選手一覧
            </Button>
            <Button onClick={onClickPlayerAdd} w="100%">
              選手登録
            </Button>
            <Button onClick={onClickPlayerStats} w="100%">
              個人成績
            </Button>
            <Button onClick={onClickGameResult} w="100%">
              試合結果
            </Button>
            <Button onClick={onClickGameResultRegister} w="100%">
              試合結果登録
            </Button>
          </DrawerBody>
        </DrawerContent>
      </DrawerOverlay>
    </Drawer>
  );
});
