import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMessage } from "./useMessage";

import { fetchAuthSession, signIn, signOut, type SignInInput } from "aws-amplify/auth";



// idTokenが存在する（サインイン済み）場合、サインアウトする
export const useAuth = () => {
  const navigate = useNavigate(); 
  const { showMessage } = useMessage();

  const [loading, setLoading] = useState(false);

  const login = useCallback(async (username: string, password: string) => {
    try {
      setLoading(true);
      const alreadyIdToken = (await fetchAuthSession()).tokens?.idToken;
      if (alreadyIdToken) {
          await signOut();
      }
      
      const signInInput : SignInInput = {
        username: username,
        password: password
      }
      // サインインを実施
      await signIn(signInInput);

      // ログイン成功後にホーム画面へ遷移
      showMessage({ title: "ログインしました", status: "info" });
      navigate('/home');  // navigateを使用してリダイレクト
    } catch (error) {
      showMessage({ title: "ログインできません", status: "error" })
      console.error('ログイン失敗:', error);
    } finally {
      setLoading(false)
    }
  },[navigate, showMessage]);

  return { login, loading };
};
