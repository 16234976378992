import GameResultRegister from "../pages/Game/GameResultAdd/GameResultRegister";
import { GameResultAll } from "../pages/Game/GameResultAll";
import { GameResultDetail } from "../pages/Game/GameResultDetail";
import { Home } from "../pages/Home";
import { PlayerAdd } from "../pages/Player/PlayerAdd";
import { PlayerAll } from "../pages/Player/PlayerAll";
import { PlayerDetail } from "../pages/Player/PlayerDetail";
import { PlayerStatsAll } from "../pages/Player/PlayerStatsAll";
import { TeamAdd } from "../pages/Team/TeamAdd";
import { TeamStats } from "../pages/Team/TeamStats";
import { TeamUpdate } from "../pages/Team/TeamUpdate";

export const HomeRoutes = [
  {
    path: "/",
    exact: true,
    children: <Home />,
  },
  {
    path: "/player",
    exact: true,
    children: <PlayerAll />,
  },
  {
    path: "/player/:id?",
    exact: true,
    children: <PlayerDetail />,
  },
  {
    path: "/player-add",
    exact: true,
    children: <PlayerAdd />,
  },
  {
    path: "/team-add",
    exact: true,
    children: <TeamAdd />,
  },
  {
    path: "/team-update/:id?",
    exact: true,
    children: <TeamUpdate />,
  },
  {
    path: "/team-stats/:id?",
    exact: true,
    children: <TeamStats />,
  },
  {
    path: "/player-stats",
    exact: true,
    children: <PlayerStatsAll />,
  },
  {
    path: "/game-result",
    exact: true,
    children: <GameResultAll />,
  },
  {
    path: "/game-result/:id?",
    exact: true,
    children: <GameResultDetail />,
  },
  {
    path: "/game-result-register",
    exact: true,
    children: <GameResultRegister />
  }
];
