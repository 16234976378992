import { PlayerPitchStatsOfGameType } from "../components/types/PlayerPitchStatsOfGameType";

export const PlayerPitchStatsOfGameData: PlayerPitchStatsOfGameType[] = [
    {
        gameId: 1,
        playerId: 101,
        playerNo: 18,
        playerName: '山田太郎',
        result: '勝',
        inningsPitched: 7,
        runsAllowed: 2,
        earnedRuns: 2,
        completeGame: true,
        shutout: false,
        hitsAllowed: 5,
        homeRunsAllowed: 1,
        strikeouts: 10,
        walksAllowed: 2,
        balks: 0,
        wildPitches: 1,
        appearanceOrder: 1,
    },
    {
        gameId: 1,
        playerId: 102,
        playerNo: 21,
        playerName: '田中花子',
        result: '負',
        inningsPitched: 5.2,
        runsAllowed: 4,
        earnedRuns: 3,
        completeGame: false,
        shutout: false,
        hitsAllowed: 8,
        homeRunsAllowed: 0,
        strikeouts: 7,
        walksAllowed: 3,
        balks: 0,
        wildPitches: 0,
        appearanceOrder: 2,
    },
    {
        gameId: 1,
        playerId: 103,
        playerNo: 15,
        playerName: '佐藤次郎',
        result: '勝',
        inningsPitched: 6,
        runsAllowed: 1,
        earnedRuns: 1,
        completeGame: false,
        shutout: true,
        hitsAllowed: 3,
        homeRunsAllowed: 0,
        strikeouts: 8,
        walksAllowed: 0,
        balks: 0,
        wildPitches: 0,
        appearanceOrder: 3,
    },
];

export default PlayerPitchStatsOfGameData;
