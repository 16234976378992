import { Flex } from "@chakra-ui/react";
import { memo, useState } from "react";
import { DataTable } from "../../molecules/table/DataTable";

import { TeamStatsData } from "../../../data/TeamStatsData";
import { TopTitle } from "../../atoms/title/TopTitle";
import { TeamStatsType } from "../../types/TeamStatsType";
import { TeamStatsColumns } from "../../../columns/TeamStatsColumns";

export const TeamStats = memo(() => {
  const [teamStatsData] = useState<TeamStatsType[]>(TeamStatsData);
  return (
    <>
      <TopTitle>チーム成績</TopTitle>

      <Flex align="top" justify="center">
        <Flex
          bg="white"
          p={4}
          borderRadius="md"
          shadow="md"
          overflowX="auto"
          mx={4}
        >
          <DataTable columns={TeamStatsColumns} data={teamStatsData} />
        </Flex>
      </Flex>
    </>
  );
});
