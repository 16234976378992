import { Box, Center, Heading, Image, Text } from "@chakra-ui/react";
import { memo } from "react";
import topLogo from "../../image/top_logo.png";

export const Home = memo(() => {
  // サンプルのお知らせデータ
  const announcements = [
    { date: "2024-04-01", content: "本日の試合が中止となりました。次回の試合にご期待ください！" },
    { date: "2024-04-02", content: "明日の練習は午前10時から開始します。" },
    { date: "2024-04-03", content: "チームの新しいメンバーが加入しました。" },
  ];

  return (
    <Center height="100vh">
      <Box width="90%" maxWidth="400px">
        {/* チームロゴ */}
        <Center mb={6}>
          <Image src={topLogo} alt="Team Logo" boxSize="150px" />
        </Center>

        {/* チーム名 */}
        <Center mb={6}>
          <Heading as="h1" size="xl" letterSpacing="tight">
            野球チーム名
          </Heading>
        </Center>

        {/* お知らせ */}
        <Box>
          <Heading as="h2" size="lg" mb={4}>
            お知らせ
          </Heading>
          {announcements.slice(0, 3).map((announcement, index) => (
            <Box key={index} p={4} borderWidth="1px" borderRadius="md" boxShadow="md" backgroundColor="white" mb={4}>
              <Text fontSize="md" mb={2}>
                {announcement.date}
              </Text>
              <Text fontSize="lg">
                {announcement.content}
              </Text>
            </Box>
          ))}
        </Box>
      </Box>
    </Center>
  );
});
