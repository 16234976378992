import { createColumnHelper } from "@tanstack/react-table";
import { PlayerStatsOfGameType } from "../components/types/PlayerStatsOfGameType";

const columnHelper = createColumnHelper<PlayerStatsOfGameType>();

export const PlayerStatsOfGameColumns = [
    columnHelper.accessor("playerNo", {
        cell: (info: any) => info.getValue(),
        header: "背番号",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("playerName", {
        cell: (info: any) => info.getValue(),
        header: "選手名",
    }),
    columnHelper.accessor("inGame", {
        cell: (info: any) => info.getValue() ? "出場" : "欠場",
        header: "出場",
    }),
    columnHelper.accessor("battingOrder", {
        cell: (info: any) => info.getValue(),
        header: "打順",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("fieldingPosition", {
        cell: (info: any) => info.getValue(),
        header: "守備",
    }),
    columnHelper.accessor("atBats", {
        cell: (info: any) => info.getValue(),
        header: "打席",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("hits", {
        cell: (info: any) => info.getValue(),
        header: "安打",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("homeRuns", {
        cell: (info: any) => info.getValue(),
        header: "本塁打",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("runsBattedIn", {
        cell: (info: any) => info.getValue(),
        header: "打点",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("runsScored", {
        cell: (info: any) => info.getValue(),
        header: "得点",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("stolenBases", {
        cell: (info: any) => info.getValue(),
        header: "盗塁",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("doubles", {
        cell: (info: any) => info.getValue(),
        header: "二塁打",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("triples", {
        cell: (info: any) => info.getValue(),
        header: "三塁打",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("strikeouts", {
        cell: (info: any) => info.getValue(),
        header: "三振",
        meta: {
            isNumeric: true,
        },
    }),
    columnHelper.accessor("walks", {
        cell: (info: any) => info.getValue(),
        header: "四死球",
        meta: {
            isNumeric: true,
        },
    }),
];
