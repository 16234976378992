import { PlayerStatsType } from "../components/types/PlayerStatsType";

export const PlayerStatsData: PlayerStatsType[] = [
  {
    id: "1",
    playerNo: 10,
    fullName: "田中 太郎",
    battingAverage: "0.300",
    plateAppearances: 500,
    atBats: 450,
    hits: 135,
    homeRuns: 20,
    RBIs: 80,
    runsScored: 70,
    stolenBases: 10,
    onBasePercentage: "0.350",
    sluggingPercentage: "0.500",
    OPS: "0.850",
    doubles: 25,
    triples: 5,
    totalBases: 225,
    strikeouts: 100,
    walks: 50,
    sacrificeHits: 2,
    sacrificeFlies: 5,
    doublePlays: 10,
    errors: 5,
    fielding: 0.975,
  },
  {
    id: "2",
    playerNo: 22,
    fullName: "山本 次郎",
    battingAverage: "0.280",
    plateAppearances: 520,
    atBats: 470,
    hits: 130,
    homeRuns: 15,
    RBIs: 70,
    runsScored: 65,
    stolenBases: 8,
    onBasePercentage: "0.330",
    sluggingPercentage: "0.450",
    OPS: "0.780",
    doubles: 20,
    triples: 3,
    totalBases: 210,
    strikeouts: 90,
    walks: 40,
    sacrificeHits: 3,
    sacrificeFlies: 6,
    doublePlays: 8,
    errors: 7,
    fielding: 0.970,
  },
  {
    id: "3",
    playerNo: 30,
    fullName: "佐藤 花子",
    battingAverage: "0.320",
    plateAppearances: 480,
    atBats: 430,
    hits: 138,
    homeRuns: 10,
    RBIs: 60,
    runsScored: 75,
    stolenBases: 15,
    onBasePercentage: "0.360",
    sluggingPercentage: "0.420",
    OPS: "0.780",
    doubles: 15,
    triples: 2,
    totalBases: 180,
    strikeouts: 70,
    walks: 55,
    sacrificeHits: 1,
    sacrificeFlies: 4,
    doublePlays: 12,
    errors: 3,
    fielding: 0.985,
  },
];
