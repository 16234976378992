import { memo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { TopTitle } from "../../atoms/title/TopTitle";
import { Box, Flex } from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { PlayerForm } from "../../organisms/form/PlayerForm";
import { PlayerData } from "../../../data/PlayerData";

export const PlayerDetail = memo(() => {
  const { id } = useParams<{ id: string }>();
  const methods = useForm();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate("/home/player");
  };

  const player = PlayerData.find((player) => player.id === id);
  return (
    <>
      <TopTitle>選手詳細</TopTitle>
      <Flex align="top" justify="center">
        <Box
          bg="white"
          w="lg"
          p={4}
          borderRadius="md"
          shadow="md"
          overflowX="auto"
          mx={4}
          mb={8}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <PlayerForm player={player} />
            </form>
          </FormProvider>
        </Box>
      </Flex>
    </>
  );
});
