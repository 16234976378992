import { Box, Flex } from "@chakra-ui/react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TeamData } from "../../../data/TeamData";
import { TopTitle } from "../../atoms/title/TopTitle";
import { TeamForm } from "../../organisms/form/TeamForm";

// @TODO チーム情報を更新できるユーザーはチーム代表者のみ。
// @TODO チーム情報はSessionStorageに保持するのもありかも。
export const TeamUpdate = memo(() => {
  // const { id } = useParams<{ id: string }>();
  const methods = useForm();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate("/home");
  };
  
  const team = TeamData
  return (
    <>
      <TopTitle>チーム更新フォーム</TopTitle>
      <Flex align="top" justify="center">
        <Box
          bg="white"
          w="lg"
          p={4}
          borderRadius="md"
          shadow="md"
          overflowX="auto"
          mx={4}
          mb={8}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <TeamForm team={team}/>
            </form>
          </FormProvider>
        </Box>
      </Flex>
    </>
  );
});
