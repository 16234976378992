import { Box } from "@chakra-ui/react";
import { FC, ReactNode, memo } from "react";

type Props = {
  children: ReactNode;
};

export const FormBox: FC<Props> = memo((props) => {
  const { children } = props;
  return (
    <Box
    bg="white"
    w="lg"
    p={4}
    borderRadius="md"
    shadow="md"
    overflowX="auto"
    mx={4}
    mb={8}
  >{children}</Box>
  );
});
