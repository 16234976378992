import { PlayerPitchStatsType } from "../components/types/PlayerPitchStatsType";

export const PlayerPitchStats: PlayerPitchStatsType[] = [
  {
    id: "1",
    playerNo: 1,
    fullName: "山田 太郎",
    games: 20,
    wins: 10,
    saves: 3,
    losses: 5,
    winRate: 0.667,
    ERA: 3.5,
    inningsPitched: 120.2,
    runsAllowed: 50,
    earnedRuns: 45,
    completeGames: 2,
    shutouts: 1,
    hitsAllowed: 110,
    homeRunsAllowed: 10,
    strikeouts: 130,
    walks: 40,
    balks: 1,
    wildPitches: 3,
  },
  {
    id: "2",
    playerNo: 10,
    fullName: "高橋 次郎",
    games: 18,
    wins: 7,
    saves: 0,
    losses: 6,
    winRate: 0.538,
    ERA: 4.2,
    inningsPitched: 110.1,
    runsAllowed: 55,
    earnedRuns: 50,
    completeGames: 0,
    shutouts: 0,
    hitsAllowed: 120,
    homeRunsAllowed: 8,
    strikeouts: 100,
    walks: 35,
    balks: 0,
    wildPitches: 5,
  },
  {
    id: "3",
    playerNo: 18,
    fullName: "一二三 晋平太",
    games: 22,
    wins: 12,
    saves: 1,
    losses: 4,
    winRate: 0.75,
    ERA: 3.0,
    inningsPitched: 150.0,
    runsAllowed: 40,
    earnedRuns: 35,
    completeGames: 3,
    shutouts: 2,
    hitsAllowed: 130,
    homeRunsAllowed: 12,
    strikeouts: 150,
    walks: 30,
    balks: 0,
    wildPitches: 4,
  },
];
