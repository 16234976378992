import { memo } from "react";
import { GameResultData } from "../../../data/GameResultData";
import { GameResultCard } from "../../organisms/card/GameResultCard";
import { Box, VStack } from "@chakra-ui/react";
import { TopTitle } from "../../atoms/title/TopTitle";
import { useNavigate } from "react-router-dom";

export const GameResultAll = memo(() => {
  const navigate = useNavigate();
  // なんか名前気持ち悪い。onClickに引数を付けられないのでしょうがなく。
  const onClick = (id: string) => {
    navigate(`/home/game-result/${id}`);
  };
  return (
    <>
      <VStack spacing={2} mb={3} mx={4}>
        <TopTitle>試合結果</TopTitle>
        {GameResultData.map((data) => {
          return (
            <Box
              key={data.id}
              w={{ base: "100%", md: "70%", lg: "60%", xl: "50%" }}
            >
              <GameResultCard data={data} onClick={() => onClick(data.id)} />
            </Box>
          );
        })}
      </VStack>
    </>
  );
});
