import { Box } from "@chakra-ui/react";
import { FC, memo } from "react";

type Props = {
  gameType: string;
};

export const GameTypeBox: FC<Props> = memo((props) => {
  const { gameType } = props;
  let color: string;
  if (gameType !== "練習試合") {
    color = "lime";
  } else {
    color = "green";
  }
  return (
    <Box
      fontSize="sm"
      color={color}
      border="1px"
      borderColor={color}
      borderRadius={4}
      p={0.5}
    >
      {gameType}
    </Box>
  );
});
