import { Box, Button, Container, VStack } from '@chakra-ui/react';
import axios from 'axios';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TopTitle } from '../../../atoms/title/TopTitle';
import PlayerSelection from './PlayerSelection';
import PlayerStatsInput from './PlayerStatsInput';
import ScoreInput from './ScoreInput';

const steps = ['試合スコア入力', '出場選手選択', '選手成績入力'];

const GameResultRegister: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [score, setScore] = useState<{ teamA: number[], teamB: number[] }>({ teamA: Array(9).fill(0), teamB: Array(9).fill(0) });
  const [players, setPlayers] = useState<{ batters: string[], pitchers: string[] }>({ batters: [], pitchers: [] });
  const [stats, setStats] = useState<{ [key: string]: any }>({});
  const navigate = useNavigate();

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      // 最終ステップで完了ボタンを押したときにデータを送信
      const data = {
        score,
        players,
        stats
      };

      axios.post('https://wyz.example.com',  { data },{
        headers: {
          Authorization: ''
        }
      })
      .then(response => {
        console.log('Data sent successfully:', response);
        navigate('/home'); // データ送信成功後に /home へ遷移
      })
      .catch(error => {
        console.error('Error sending data:', error);

      });
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleScoreChange = (newScore: { teamA: number[], teamB: number[] }) => {
    setScore(newScore);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handlePlayersChange = (newPlayers: { batters: string[], pitchers: string[] }) => {
    setPlayers(newPlayers);
  };

  const handleStatsChange = (newStats: { [key: string]: any }) => {
    setStats(newStats);
  };

  return (
    <Container>
      <Box>
        <VStack align="center" spacing={2} mb={3} mx={4}>
        <TopTitle>試合結果登録</TopTitle>
          {activeStep === 0 && <ScoreInput score={score} onScoreChange={handleScoreChange} />}
          {activeStep === 1 && <PlayerSelection players={players} onPlayersChange={handlePlayersChange} />}
          {activeStep === 2 && <PlayerStatsInput players={players} stats={stats} onStatsChange={handleStatsChange} />}
        </VStack>
        <Box my={4} display="flex" justifyContent="space-between">
          <Button colorScheme="gray" variant="outline" onClick={handleBack} isDisabled={activeStep === 0}>
            戻る
          </Button>
          <Button onClick={handleNext}>
            {activeStep === steps.length - 1 ? '完了' : '次へ'}
          </Button>
        </Box>
      </Box>
    </Container>
  );
};

export default GameResultRegister;
