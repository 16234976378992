import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { RLData } from "../../../data/RLData";
import { positionData } from "../../../data/positionData";
import { FormNumberInput } from "../../molecules/formInput/FormNumberInput";
import { FormSelect } from "../../molecules/formInput/FormSelect";
import { FormTextInput } from "../../molecules/formInput/FormTextInput";
import { PlayerType } from "../../types/PlayerType";

type Props = {
  player?: PlayerType;
};

export const PlayerForm: FC<Props> = (props) => {
  const { player } = props;

  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={4} p={3}>
        <GridItem>
          <FormNumberInput
            id="playerNo"
            label="背番号"
            placeholder=""
            isInvalid={errors?.playerNo}
            defaultValue={player?.playerNo}
            register={register("playerNo", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem></GridItem>
        <GridItem>
          <FormTextInput
            id="lastName"
            label="氏"
            placeholder=""
            isInvalid={errors?.lastName}
            defaultValue={player?.lastName}
            register={register("lastName", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="firstName"
            label="名"
            placeholder=""
            isInvalid={errors?.firstName}
            defaultValue={player?.firstName}
            register={register("firstName", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem>
          <FormNumberInput
            id="age"
            label="年齢"
            placeholder=""
            isInvalid={errors?.age}
            defaultValue={player?.age}
            register={register("age", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem>
          <FormSelect
            id="position"
            label="守備位置"
            placeholder="-"
            isInvalid={errors?.position}
            defaultValue={player?.position}
            register={register("position", {
              required: "入力必須です",
            })}
            optionData={positionData}
          />
        </GridItem>
        <GridItem>
          <FormSelect
            id="throw"
            label="投球"
            placeholder="-"
            isInvalid={errors?.throw}
            defaultValue={player?.throw}
            register={register("throw", {
              required: "入力必須です",
            })}
            optionData={RLData}
          />
        </GridItem>
        <GridItem>
          <FormSelect
            id="hit"
            label="打席"
            placeholder="-"
            isInvalid={errors?.hit}
            defaultValue={player?.hit}
            register={register("hit", {
              required: "入力必須です",
            })}
            optionData={RLData}
          />
        </GridItem>
        <GridItem>
          <FormSelect
            id="joinedAt"
            label="入部年"
            placeholder=""
            isInvalid={errors?.joinedAt}
            register={register("joinedAt", {
              required: "入力必須です",
            })}
            defaultValue={
              player?.joinedAt === undefined
                ? new Date().getFullYear().toString()
                : player?.joinedAt
            }
            optionData={["2020", "2021", "2022", "2023", "2024", "2025"]}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="role"
            label="役割"
            placeholder=""
            isInvalid={errors?.role}
            defaultValue={player?.role}
            register={register("role", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Flex justify="right">
            <Button
              mt={4}
              bg="teal.400"
              color="white"
              _hover={{ opacity: 0.8 }}
              isLoading={isSubmitting}
              type="submit"
            >
              保存
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
};
