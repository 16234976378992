import { Flex } from "@chakra-ui/react";
import { memo, useState } from "react";
import { PlayerPitchStats } from "../../../data/PlayerPitchStatsData";
import {
  PlayerStatsData,
} from "../../../data/PlayerStatsData";
import { TopTitle } from "../../atoms/title/TopTitle";
import { PrimaryButton } from "../../atoms/button/PrimaryButton";
import { DataTable } from "../../molecules/table/DataTable";
import { PlayerStatsColumns } from "../../../columns/PlayerStatsColumns";
import { PlayerPitchStatsColumns } from "../../../columns/PlayerPitchStatColumns";

export const PlayerStatsAll = memo(() => {
  const [statsState, setStatsState] = useState<"野手" | "投手">("野手");
  const [titleState, setTitleState] = useState<"野手成績" | "投手成績">(
    "野手成績"
  );

  const onClick = () => {
    switch (statsState) {
      case "野手":
        setStatsState("投手");
        setTitleState("投手成績");
        break;
      case "投手":
        setStatsState("野手");
        setTitleState("野手成績");
        break;
    }
  };
  return (
    <>
      <TopTitle>{titleState}</TopTitle>
      <Flex justify="center" m={4}>
        <PrimaryButton onClick={onClick}>野手・投手切り替え</PrimaryButton>
      </Flex>
      <Flex align="top" justify="center">
        <Flex
          bg="white"
          p={4}
          mx={4}
          borderRadius="md"
          shadow="md"
          overflow="auto"
        >
          {statsState === "野手" ? (
            <DataTable columns={PlayerStatsColumns} data={PlayerStatsData} />
          ) : (
            <DataTable columns={PlayerPitchStatsColumns} data={PlayerPitchStats}/>
          )}
        </Flex>
      </Flex>
    </>
  );
});
