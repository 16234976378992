import { PlayerStatsOfGameType } from "../components/types/PlayerStatsOfGameType";

export const PlayerStatsOfGameData: PlayerStatsOfGameType[] = [
    {
        gameId: 1,
        playerId: 101,
        playerNo: 24,
        playerName: '山田太郎',
        inGame: true,
        battingOrder: 1,
        fieldingPosition: '外野手',
        atBats: 4,
        hits: 2,
        homeRuns: 1,
        runsBattedIn: 3,
        runsScored: 2,
        stolenBases: 0,
        doubles: 1,
        triples: 0,
        strikeouts: 1,
        walks: 1,
    },
    {
        gameId: 1,
        playerId: 102,
        playerNo: 7,
        playerName: '田中花子',
        inGame: true,
        battingOrder: 2,
        fieldingPosition: '内野手',
        atBats: 3,
        hits: 1,
        homeRuns: 0,
        runsBattedIn: 0,
        runsScored: 1,
        stolenBases: 1,
        doubles: 0,
        triples: 0,
        strikeouts: 0,
        walks: 1,
    },
    {
        gameId: 1,
        playerId: 103,
        playerNo: 12,
        playerName: '佐藤次郎',
        inGame: true,
        battingOrder: 3,
        fieldingPosition: '捕手',
        atBats: 4,
        hits: 1,
        homeRuns: 0,
        runsBattedIn: 1,
        runsScored: 0,
        stolenBases: 0,
        doubles: 0,
        triples: 0,
        strikeouts: 2,
        walks: 0,
    },
    {
        gameId: 1,
        playerId: 104,
        playerNo: 8,
        playerName: '鈴木花子',
        inGame: false,
        battingOrder: 4,
        fieldingPosition: '投手',
        atBats: 0,
        hits: 0,
        homeRuns: 0,
        runsBattedIn: 0,
        runsScored: 0,
        stolenBases: 0,
        doubles: 0,
        triples: 0,
        strikeouts: 0,
        walks: 0,
    },
    {
        gameId: 1,
        playerId: 105,
        playerNo: 19,
        playerName: '木村健太',
        inGame: true,
        battingOrder: 5,
        fieldingPosition: '遊撃手',
        atBats: 3,
        hits: 2,
        homeRuns: 0,
        runsBattedIn: 1,
        runsScored: 1,
        stolenBases: 1,
        doubles: 1,
        triples: 0,
        strikeouts: 0,
        walks: 0,
    },
    {
        gameId: 1,
        playerId: 106,
        playerNo: 21,
        playerName: '高橋芳子',
        inGame: true,
        battingOrder: 6,
        fieldingPosition: '外野手',
        atBats: 4,
        hits: 3,
        homeRuns: 1,
        runsBattedIn: 3,
        runsScored: 2,
        stolenBases: 1,
        doubles: 2,
        triples: 0,
        strikeouts: 0,
        walks: 0,
    },
    {
        gameId: 1,
        playerId: 107,
        playerNo: 15,
        playerName: '伊藤悠希',
        inGame: true,
        battingOrder: 7,
        fieldingPosition: '一塁手',
        atBats: 4,
        hits: 2,
        homeRuns: 0,
        runsBattedIn: 1,
        runsScored: 1,
        stolenBases: 1,
        doubles: 1,
        triples: 0,
        strikeouts: 1,
        walks: 0,
    },
    {
        gameId: 1,
        playerId: 108,
        playerNo: 33,
        playerName: '加藤太一',
        inGame: true,
        battingOrder: 8,
        fieldingPosition: '二塁手',
        atBats: 3,
        hits: 1,
        homeRuns: 0,
        runsBattedIn: 0,
        runsScored: 0,
        stolenBases: 0,
        doubles: 0,
        triples: 0,
        strikeouts: 0,
        walks: 1,
    },
    {
        gameId: 1,
        playerId: 109,
        playerNo: 9,
        playerName: '吉田花子',
        inGame: true,
        battingOrder: 9,
        fieldingPosition: '三塁手',
        atBats: 2,
        hits: 1,
        homeRuns: 0,
        runsBattedIn: 0,
        runsScored: 0,
        stolenBases: 0,
        doubles: 0,
        triples: 0,
        strikeouts: 0,
        walks: 1,
    },
];

export default PlayerStatsOfGameData;
