import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Flex,
  Grid,
  GridItem,
  VStack,
} from "@chakra-ui/react";
import { FC, memo } from "react";
import { GameResultBox } from "../../atoms/card/GameResultBox";
import { GameTypeBox } from "../../atoms/card/GameTypeBox";
import { GameResultType } from "../../types/GameResultType";

type Props = {
  data: GameResultType;
  onClick: () => void;
};

export const GameResultCard: FC<Props> = memo((props) => {
  const { data, onClick } = props;
  const {
    datetime,
    gameType,
    gameResult,
    topTeamName,
    bottomTeamName,
    topTeamAllScore,
    bottomTeamAllScore,
  } = data;

  return (
    <>
      <Card
        borderRadius="md"
        shadow="md"
        onClick={onClick}
        cursor="pointer"
        _hover={{ backgroundColor: "gray.200" }}
      >
        <CardHeader p={2}>
          <VStack spacing={1}>
            <Box>{datetime}</Box>
            <GameTypeBox gameType={gameType} />
          </VStack>
        </CardHeader>
        <CardBody px={4} pb={4} pt={2}>
          <Grid templateColumns="repeat(3, 1fr)">
            <GridItem colSpan={3}>
              <GameResultBox gameResult={gameResult} />
            </GridItem>
            <GridItem>
              <Flex
                justify="center"
                align="center"
                height="100%"
                fontSize={24}
                textAlign="center"
              >
                {topTeamName}
              </Flex>
            </GridItem>
            <GridItem>
              <Flex justify="center" align="center" height="100%">
                <Box
                  mx={4}
                  fontSize={36}
                  height="auto"
                  fontWeight="bold"
                  whiteSpace="nowrap"
                >
                  {topTeamAllScore + " - " + bottomTeamAllScore}
                </Box>
              </Flex>
            </GridItem>
            <GridItem>
              <Flex
                justify="center"
                align="center"
                height="100%"
                fontSize={24}
                textAlign="center"
              >
                {bottomTeamName}
              </Flex>
            </GridItem>
          </Grid>
        </CardBody>
      </Card>
    </>
  );
});
