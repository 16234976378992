import { createColumnHelper } from "@tanstack/react-table";
import { TeamStatsType } from "../components/types/TeamStatsType";

const columnHelper = createColumnHelper<TeamStatsType>();

export const TeamStatsColumns = [
  columnHelper.accessor("year", {
    cell: (info) => info.getValue(),
    header: "年",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("gamesPlayed", {
    cell: (info) => info.getValue(),
    header: "試合",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("wins", {
    cell: (info) => info.getValue(),
    header: "勝ち",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("losses", {
    cell: (info) => info.getValue(),
    header: "負け",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("draws", {
    cell: (info) => info.getValue(),
    header: "引分",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("winRate", {
    cell: (info) => info.getValue(),
    header: "勝率",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("runsScored", {
    cell: (info) => info.getValue(),
    header: "得点",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("runsAllowed", {
    cell: (info) => info.getValue(),
    header: "失点",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("battingAverage", {
    cell: (info) => info.getValue(),
    header: "打率",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("homeRuns", {
    cell: (info) => info.getValue(),
    header: "本塁打",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("stolenBases", {
    cell: (info) => info.getValue(),
    header: "盗塁",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("ERA", {
    cell: (info) => info.getValue(),
    header: "防御率",
    meta: {
      isNumeric: true,
    },
  }),
];

