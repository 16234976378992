/**
 * 選手成績入力
 */
import React from "react";
import { Box, Text, Input } from "@chakra-ui/react";
import { FormBox } from "../../../atoms/FormBox";
import { MiddleTitle } from "../../../atoms/title/MiddleTitle";

interface PlayerStatsInputProps {
  players: { batters: string[]; pitchers: string[] };
  stats: { [key: string]: any };
  onStatsChange: (stats: { [key: string]: any }) => void;
}

const PlayerStatsInput: React.FC<PlayerStatsInputProps> = ({
  players,
  stats,
  onStatsChange,
}) => {
  const handleStatChange = (player: string, stat: string, value: any) => {
    onStatsChange({
      ...stats,
      [player]: {
        ...stats[player],
        [stat]: value,
      },
    });
  };

  return (
    <>
      <FormBox>
        <MiddleTitle>野手成績入力</MiddleTitle>
        {players.batters.map((batter, index) => (
          <Box key={index} mt={4} mb={4}>
            <Text fontSize="md" fontWeight="medium">
              {batter}
            </Text>
            <Input
              placeholder="打数"
              value={stats[batter]?.atBats || ""}
              onChange={(e) =>
                handleStatChange(batter, "atBats", e.target.value)
              }
              type="number"
              mt={2}
            />
            <Input
              placeholder="安打"
              value={stats[batter]?.hits || ""}
              onChange={(e) => handleStatChange(batter, "hits", e.target.value)}
              type="number"
              mt={2}
            />
          </Box>
        ))}
      </FormBox>
      <FormBox>
        <MiddleTitle>投手成績入力</MiddleTitle>
        {players.pitchers.map((pitcher, index) => (
          <Box key={index} mt={4} mb={4}>
            <Text fontSize="md" fontWeight="medium">
              {pitcher}
            </Text>
            <Input
              placeholder="投球回"
              value={stats[pitcher]?.inningsPitched || ""}
              onChange={(e) =>
                handleStatChange(pitcher, "inningsPitched", e.target.value)
              }
              type="number"
              mt={2}
            />
            <Input
              placeholder="奪三振"
              value={stats[pitcher]?.strikeouts || ""}
              onChange={(e) =>
                handleStatChange(pitcher, "strikeouts", e.target.value)
              }
              type="number"
              mt={2}
            />
          </Box>
        ))}
      </FormBox>
    </>
  );
};

export default PlayerStatsInput;
