import { TeamType } from "../components/types/TeamType";

export const TeamData: TeamType = {
  id: "team01",
  teamName: "Tokyo Titans",
  teamNameKana: "トウキョウ タイタンズ",
  homeBase: "東京",
  teamType: "社会人クラブ",
  teamLevel: "上級",
  averageAge: 27,
  teamExperience: "多数の選手が高校、大学で野球経験あり",
  affiliatedLeague: "関東社会人野球リーグ",
  majorTitle: "関東大会優勝、全国大会出場",
  establishmentYear: 2010,
  activityDay: "土日祝",
  activityFrequency: "週2回",
  annualGames: 30,
  teamSlogan: "一球入魂、全力プレイ！",
  teamIntroduction: "東京を拠点に活動する社会人野球チームです。野球を通じて、選手一人ひとりが成長し、チーム全体で最高の成果を目指しています。",
  externalSite: "https://www.tokyotitansbaseball.com",
};