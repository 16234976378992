import { GameResultType } from "../components/types/GameResultType";
import {
  gameScoreDataList1,
  gameScoreDataList2,
  gameScoreDataList3,
} from "./GameScoreDataList";

export const GameResultData: GameResultType[] = [
  {
    id: "1",
    datetime: "2023/12/23 10:00～12:00",
    gameType: "練習試合",
    gameResult: "1", // 負け|引き分け|勝ち
    topTeamName: "South",
    bottomTeamName: "TOKYO Winx",
    topTeamAllScore: 1,
    bottomTeamAllScore: 14,
    gameScoreList: gameScoreDataList3,
  },
  {
    id: "2",
    datetime: "2023/12/16 08:00～10:00",
    gameType: "練習試合",
    gameResult: "-1", // 負け|引き分け|勝ち
    topTeamName: "Backdrop",
    bottomTeamName: "TOKYO Winx",
    topTeamAllScore: 3,
    bottomTeamAllScore: 2,
    gameScoreList: gameScoreDataList2,
  },
  {
    id: "3",
    datetime: "2023/12/9 12:00～14:00",
    gameType: "練習試合",
    gameResult: "0", // 負け|引き分け|勝ち
    topTeamName: "TOKYO Winx",
    bottomTeamName: "SEIREN TOKYO",
    topTeamAllScore: 5,
    bottomTeamAllScore: 5,
    gameScoreList: gameScoreDataList1,
  },
];
