import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FC } from "react";

type Props = {
  register: any;
  id: string;
  label: string;
  isInvalid?: any;
  placeholder: string;
  defaultValue?: number;
};

export const FormNumberInput: FC<Props> = (props) => {
  const { register, id, label, isInvalid, placeholder, defaultValue } = props;

  return (
    <>
      <FormControl id={id} isInvalid={!!isInvalid}>
        <FormLabel htmlFor={id}>{label}</FormLabel>
        <Box>
          <Input
            {...register}
            type="number"
            placeholder={placeholder}
            variant="flushed"
            defaultValue={defaultValue}
          />
          <FormErrorMessage>
            {isInvalid && typeof isInvalid.message === "number"
              ? isInvalid.message
              : ""}
          </FormErrorMessage>
        </Box>
      </FormControl>
    </>
  );
};
