import { Flex } from "@chakra-ui/react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { FormBox } from "../../atoms/FormBox";
import { TopTitle } from "../../atoms/title/TopTitle";
import { PlayerForm } from "../../organisms/form/PlayerForm";

export const PlayerAdd = memo(() => {
  const methods = useForm();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate("/home/player");
  };
  return (
    <>
      <TopTitle>選手登録フォーム</TopTitle>
      <Flex align="top" justify="center">
        <FormBox>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <PlayerForm />
            </form>
          </FormProvider>
        </FormBox>
      </Flex>
    </>
  );
});
