import { Flex } from "@chakra-ui/react";
import { memo, useState } from "react";
import { DataTable } from "../../molecules/table/DataTable";

import { PlayerData } from "../../../data/PlayerData";
import { TopTitle } from "../../atoms/title/TopTitle";
import { PlayerType } from "../../types/PlayerType";
import { PlayerColumns } from "../../../columns/PlayerColumns";
import { TableBox } from "../../atoms/TableBox";

export const PlayerAll = memo(() => {
  const [playerData] = useState<PlayerType[]>(PlayerData);
  return (
    <>
      <TopTitle>選手一覧</TopTitle>

      <Flex align="top" justify="center">
        <TableBox>
          <DataTable columns={PlayerColumns} data={playerData} />
        </TableBox>
      </Flex>
    </>
  );
});
