import { TeamStatsType } from "../components/types/TeamStatsType";

export const TeamStatsData: TeamStatsType[] = [
  {
    teamId: "WINXID",
    year: 2023,
    gamesPlayed: 60,
    wins: 34,
    losses: 26,
    draws: 0,
    winRate: 0.567,
    runsScored: 850,
    runsAllowed: 750,
    battingAverage: 0.265,
    homeRuns: 180,
    stolenBases: 100,
    ERA: 3.75,
  }
];
