import { Flex } from "@chakra-ui/react";
import { FC, ReactNode, memo } from "react";

type Props = {
  children: ReactNode;
};

export const MiddleTitle: FC<Props> = memo((props) => {
  const { children } = props;
  return (
    <Flex fontSize={16} fontWeight="Bold" justify="left" align="center" p={1}>
      {children}
    </Flex>
  );
});
