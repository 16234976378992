import { createColumnHelper } from "@tanstack/react-table";
import { PlayerType } from "../components/types/PlayerType";

const columnHelper = createColumnHelper<PlayerType>();

export const PlayerColumns = [
  columnHelper.accessor("playerNo", {
    cell: (info) => info.getValue(),
    header: "背番号",
    meta: {
      isNumeric: true,
      isLink: true,
      path: "/home/player",
      linkKey: "id",
    },
  }),
  columnHelper.accessor("fullName", {
    cell: (info) => info.getValue(),
    header: "氏名",
  }),
  columnHelper.accessor("age", {
    cell: (info) => info.getValue(),
    header: "年齢",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("position", {
    cell: (info) => info.getValue(),
    header: "守備位置",
  }),
  columnHelper.accessor("throw", {
    cell: (info) => info.getValue(),
    header: "投球",
  }),
  columnHelper.accessor("hit", {
    cell: (info) => info.getValue(),
    header: "打席",
  }),
  columnHelper.accessor("joinedAt", {
    cell: (info) => info.getValue(),
    header: "入団年",
  }),
  columnHelper.accessor("role", {
    cell: (info) => info.getValue(),
    header: "担当",
  }),
];
