import { Box, Flex, Link, useDisclosure } from "@chakra-ui/react";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { MenuIconButton } from "../../atoms/button/MenuIconButton";
import { MenuDrawer } from "../../molecules/MenuDrawer";

export const Header = memo(() => {
  const teamId = "WINXID" //@TODO 認証情報から取得する
  const { isOpen, onOpen, onClose } = useDisclosure();

  const navigate = useNavigate();

  const onClickHome = useCallback(() => {
    navigate("/home");
    onClose();
  }, [navigate, onClose]);

  const onClickPlayer = useCallback(() => {
    navigate("/home/player");
    onClose();
  }, [navigate, onClose]);

  const onClickPlayerAdd = useCallback(() => {
    navigate("/home/player-add");
    onClose();
  }, [navigate, onClose]);

  const onClickTeamStats = useCallback(() => {
    navigate(`/home/team-stats/${teamId}`);
    onClose();
  }, [navigate, onClose]);

  const onClickPlayerStats = useCallback(() => {
    navigate("/home/player-stats");
    onClose();
  }, [navigate, onClose]);

  const onClickGameResult = useCallback(() => {
    navigate("/home/game-result");
    onClose();
  }, [navigate, onClose]);

  const onClickGameResultRegister = useCallback(() => {
    navigate("/home/game-result-register");
    onClose();
  }, [navigate, onClose])

  return (
    <>
      <Flex
        as="nav"
        // bg="gray.200"
        bg="rgba(0,0,0,0.5)"
        color="white"
        align="center"
        justify="space-between"
        padding={{ base: 3, md: 5 }}
      >
        <Flex
          align="center"
          as="a"
          mr={8}
          _hover={{ cursor: "pointer" }}
          onClick={onClickHome}
        >
          <Box opacity={1}>
            <Box as="h1" fontSize={{ base: "md", md: "lg" }} color="white">
              成績管理アプリ
            </Box>
          </Box>
        </Flex>

        <Flex
          align="center"
          fontSize="sm"
          flexGrow={2}
          display={{ base: "none", md: "flex" }}
        >
          <Link onClick={onClickPlayer} pr={4}>
            選手一覧
          </Link>
          <Link onClick={onClickPlayerAdd} pr={4}>
            選手登録
          </Link>
          <Link onClick={onClickTeamStats} pr={4}>
            チーム成績
          </Link>
          <Link onClick={onClickPlayerStats} pr={4}>
            個人成績
          </Link>
          <Link onClick={onClickGameResult} pr={4}>
            試合結果
          </Link>
          <Link onClick={onClickGameResultRegister} pr={4}>
            試合結果登録
          </Link>
        </Flex>
        <MenuIconButton onOpen={onOpen} />
      </Flex>
      <MenuDrawer
        isOpen={isOpen}
        onClose={onClose}
        onClickHome={onClickHome}
        onClickPlayer={onClickPlayer}
        onClickPlayerAdd={onClickPlayerAdd}
        onClickPlayerStats={onClickPlayerStats}
        onClickGameResult={onClickGameResult}
        onClickGameResultRegister={onClickGameResultRegister}
      />
    </>
  );
});
