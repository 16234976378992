import { Button, Flex, Grid, GridItem } from "@chakra-ui/react";
import { FC } from "react";
import { useFormContext } from "react-hook-form";
import { FormNumberInput } from "../../molecules/formInput/FormNumberInput";
import { FormTextInput } from "../../molecules/formInput/FormTextInput";
import { TeamType } from "../../types/TeamType";

type Props = {
  team?: TeamType;
};

export const TeamForm: FC<Props> = (props) => {
  const { team } = props;

  const {
    register,
    formState: { errors, isSubmitting },
  } = useFormContext();

  return (
    <>
      <Grid templateColumns="repeat(2, 1fr)" gap={4} p={3}>
        <GridItem>
          <FormTextInput
            id="teamName"
            label="チーム名称"
            placeholder=""
            isInvalid={errors?.teamName}
            defaultValue={team?.teamName}
            register={register("teamName", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="teamNameKana"
            label="チーム名称カナ"
            placeholder=""
            isInvalid={errors?.teamNameKana}
            defaultValue={team?.teamNameKana}
            register={register("teamNameKana", {
              required: "入力必須です",
            })}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="homeBase"
            label="活動拠点"
            placeholder=""
            isInvalid={errors?.homeBase}
            defaultValue={team?.homeBase}
            register={register("homeBase")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="teamType"
            label="チーム属性"
            placeholder=""
            isInvalid={errors?.teamType}
            defaultValue={team?.teamType}
            register={register("teamType")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="teamLevel"
            label="チームレベル"
            placeholder=""
            isInvalid={errors?.teamLevel}
            defaultValue={team?.teamLevel}
            register={register("teamLevel")}
          />
        </GridItem>
        <GridItem>
          <FormNumberInput
            id="averageAge"
            label="チーム平均年齢"
            placeholder=""
            isInvalid={errors?.averageAge}
            defaultValue={team?.averageAge}
            register={register("averageAge")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="teamExperience"
            label="主なメンバーの野球歴"
            placeholder=""
            isInvalid={errors?.teamExperience}
            defaultValue={team?.teamExperience}
            register={register("teamExperience")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="affiliatedLeague"
            label="所属リーグ・団体等"
            placeholder=""
            isInvalid={errors?.affiliatedLeague}
            defaultValue={team?.affiliatedLeague}
            register={register("affiliatedLeague")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="majorTitles"
            label="主なチームタイトル"
            placeholder=""
            isInvalid={errors?.majorTitle}
            defaultValue={team?.majorTitle}
            register={register("majorTitle")}
          />
        </GridItem>
        <GridItem>
          <FormNumberInput
            id="establishmentYear"
            label="結成時期"
            placeholder=""
            isInvalid={errors?.establishmentYear}
            defaultValue={team?.establishmentYear}
            register={register("establishmentYear")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="activityDays"
            label="活動曜日"
            placeholder=""
            isInvalid={errors?.activityDay}
            defaultValue={team?.activityDay}
            register={register("activityDay")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="activityFrequency"
            label="活動頻度"
            placeholder=""
            isInvalid={errors?.activityFrequency}
            defaultValue={team?.activityFrequency}
            register={register("activityFrequency")}
          />
        </GridItem>
        <GridItem>
          <FormNumberInput
            id="annualGames"
            label="年間試合数"
            placeholder=""
            isInvalid={errors?.annualGames}
            defaultValue={team?.annualGames}
            register={register("annualGames")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="teamSlogan"
            label="チームスローガン"
            placeholder=""
            isInvalid={errors?.teamSlogan}
            defaultValue={team?.teamSlogan}
            register={register("teamSlogan")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="teamIntroduction"
            label="チーム紹介"
            placeholder=""
            isInvalid={errors?.teamIntroduction}
            defaultValue={team?.teamIntroduction}
            register={register("teamIntroduction")}
          />
        </GridItem>
        <GridItem>
          <FormTextInput
            id="externalSites"
            label="外部サイト"
            placeholder=""
            isInvalid={errors?.externalSite}
            defaultValue={team?.externalSite}
            register={register("externalSite")}
          />
        </GridItem>
        <GridItem colSpan={2}>
          <Flex justify="right">
            <Button
              mt={4}
              bg="teal.400"
              color="white"
              _hover={{ opacity: 0.8 }}
              isLoading={isSubmitting}
              type="submit"
            >
              保存
            </Button>
          </Flex>
        </GridItem>
      </Grid>
    </>
  );
};
