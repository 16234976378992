import { Box, VStack } from "@chakra-ui/react";
import { memo } from "react";
import { useParams } from "react-router-dom";
import { PlayerStatsOfGameColumns } from "../../../columns/PlayerStatsOfGameColumns";
import { GameResultData } from "../../../data/GameResultData";
import { PlayerStatsOfGameData } from "../../../data/PlayerStatsOfGameData";
import { TopTitle } from "../../atoms/title/TopTitle";
import { DataTable } from "../../molecules/table/DataTable";
import { GameResultTable } from "../../molecules/table/GameResultTable";
import { PlayerPitchStatsOfGameColumns } from "../../../columns/PlayerPitchStatsOfGameColumns";
import PlayerPitchStatsOfGameData from "../../../data/PlayerPitchStatsOfGameData";
import { TableBox } from "../../atoms/TableBox";
import { MiddleTitle } from "../../atoms/title/MiddleTitle";

export const GameResultDetail = memo(() => {
  const { id } = useParams<{ id: string }>();
  // TBD
  const i = Number(id) - 1;
  return (
    <>
      <TopTitle>試合結果詳細</TopTitle>

      <VStack align="center">
        <TableBox>
          <GameResultTable
            // TBD mockData
            gameResult={GameResultData[i]}
            gameScoreDataList={GameResultData[i]?.gameScoreList || []}
            style={{
              border: "1px solid",
              borderColor: "gray.100",
              hColor: "white",
              hBackgroundColor: "green.500",
            }}
          />
          <Box m={3}>
            <p>勝</p>
            <p>負</p>
            <p>S</p>
            <p>HR</p>
          </Box>
        </TableBox>
        <Box w="lg">
          <MiddleTitle>野手成績</MiddleTitle>
        </Box>
        <TableBox>
          <DataTable
            columns={PlayerStatsOfGameColumns}
            data={PlayerStatsOfGameData}
          />
        </TableBox>
        <Box w="lg">
          <MiddleTitle>投手成績</MiddleTitle>
        </Box>
        <TableBox>
          <DataTable
            columns={PlayerPitchStatsOfGameColumns}
            data={PlayerPitchStatsOfGameData}
          />
        </TableBox>
      </VStack>
    </>
  );
});
