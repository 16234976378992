/**
 * 出場選手選択
 */
import { Checkbox, VStack } from "@chakra-ui/react";
import React from "react";
import { FormBox } from "../../../atoms/FormBox";
import { MiddleTitle } from "../../../atoms/title/MiddleTitle";

interface PlayerSelectionProps {
  players: { batters: string[]; pitchers: string[] };
  onPlayersChange: (newPlayers: {
    batters: string[];
    pitchers: string[];
  }) => void;
}

const allBatters = ["Batter1", "Batter2", "Batter3"]; // 仮のデータ
const allPitchers = ["Pitcher1", "Pitcher2", "Pitcher3"]; // 仮のデータ

const PlayerSelection: React.FC<PlayerSelectionProps> = ({
  players,
  onPlayersChange,
}) => {
  const handleBatterChange = (batter: string) => {
    const newBatters = players.batters.includes(batter)
      ? players.batters.filter((b) => b !== batter)
      : [...players.batters, batter];
    onPlayersChange({ ...players, batters: newBatters });
  };

  const handlePitcherChange = (pitcher: string) => {
    const newPitchers = players.pitchers.includes(pitcher)
      ? players.pitchers.filter((p) => p !== pitcher)
      : [...players.pitchers, pitcher];
    onPlayersChange({ ...players, pitchers: newPitchers });
  };

  return (
    <>
      <FormBox>
        <MiddleTitle>出場した野手を選択</MiddleTitle>
        <VStack align="start" mt={2}>
          {allBatters.map((batter) => (
            <Checkbox
              key={batter}
              isChecked={players.batters.includes(batter)}
              onChange={() => handleBatterChange(batter)}
            >
              {batter}
            </Checkbox>
          ))}
        </VStack>
      </FormBox>
      <FormBox>
        <MiddleTitle>出場した投手を選択</MiddleTitle>
        <VStack align="start" mt={2}>
          {allPitchers.map((pitcher) => (
            <Checkbox
              key={pitcher}
              isChecked={players.pitchers.includes(pitcher)}
              onChange={() => handlePitcherChange(pitcher)}
            >
              {pitcher}
            </Checkbox>
          ))}
        </VStack>
      </FormBox>
    </>
  );
};

export default PlayerSelection;
