import { Box, Flex } from "@chakra-ui/react";
import { FC, memo } from "react";

type Props = {
  gameResult: "-1" | "0" | "1";
};

export const GameResultBox: FC<Props> = memo((props) => {
  const { gameResult } = props;
  let color: string;
  let gameResultValue: string;
  switch (gameResult) {
    case "-1":
      color = "blue";
      gameResultValue = "負け";
      break;
    case "0":
      color = "purple";
      gameResultValue = "引き分け";
      break;
    case "1":
      color = "red";
      gameResultValue = "勝ち";
      break;
  }
  return (
    <Flex justify="center">
      <Box fontSize={16} color={color}>
        {gameResultValue}
      </Box>
    </Flex>
  );
});
