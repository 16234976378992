import { Box, Flex } from "@chakra-ui/react";
import { memo } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { TopTitle } from "../../atoms/title/TopTitle";
import { TeamForm } from "../../organisms/form/TeamForm";

export const TeamAdd = memo(() => {
  const methods = useForm();
  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    console.log(data);
    navigate("/home");
  };
  return (
    <>
      <TopTitle>チーム登録フォーム</TopTitle>
      <Flex align="top" justify="center">
        <Box
          bg="white"
          w="lg"
          p={4}
          borderRadius="md"
          shadow="md"
          overflowX="auto"
          mx={4}
          mb={8}
        >
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(onSubmit)}>
              <TeamForm />
            </form>
          </FormProvider>
        </Box>
      </Flex>
    </>
  );
});
