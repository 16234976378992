import { Flex, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { FC, memo } from "react";
import { GameResultType } from "../../types/GameResultType";
import { GameScoreInningType } from "../../types/GameScoreInningType";

export type GameResultTableProps = {
  gameResult: GameResultType;
  gameScoreDataList: GameScoreInningType[];
  style: GameResultTableStyle;
};

export type GameResultTableStyle = {
  border: string;
  borderColor: string;
  hColor: string;
  hBackgroundColor: string;
};

export const GameResultTable: FC<GameResultTableProps> = memo((props) => {
  const { gameResult, gameScoreDataList, style } = props;
  const { topTeamName, bottomTeamName, topTeamAllScore, bottomTeamAllScore } =
    gameResult;
  const { border, borderColor, hColor, hBackgroundColor } = style;

  return (
    <Table border={border} borderColor={borderColor}>
      <Thead>
        <Tr>
          <Th
            w={32}
            px={1}
            color={hColor}
            backgroundColor={hBackgroundColor}
            borderInlineEnd={border}
            borderInlineEndColor={borderColor}
          >
            <Flex justify="center" color={hColor}>
              チーム
            </Flex>
          </Th>
          {gameScoreDataList.map((data) => {
            return (
              <Th key={data.inning} px={1} backgroundColor={hBackgroundColor}>
                <Flex justify="center" color={hColor}>
                  {data.inning}
                </Flex>
              </Th>
            );
          })}
          <Th
            px={1}
            color={hColor}
            backgroundColor={hBackgroundColor}
            borderInlineStart={border}
            borderInlineStartColor={borderColor}
          >
            <Flex justify="center">R</Flex>
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {/*先攻チーム列 */}
        <Tr>
          <Td
            px={1}
            borderInlineEnd={border}
            borderInlineEndColor={borderColor}
          >
            <Flex justify="center" textAlign="center">
              {topTeamName}
            </Flex>
          </Td>
          {gameScoreDataList.map((data) => {
            return (
              <Td key={data.inning} px={1}>
                <Flex justify="center">{data.topTeamPoint}</Flex>
              </Td>
            );
          })}
          <Td
            px={1}
            borderInlineStart={border}
            borderInlineStartColor={borderColor}
          >
            <Flex justify="center">{topTeamAllScore}</Flex>
          </Td>
        </Tr>
        {/*後攻チーム列 */}
        <Tr>
          <Td
            px={1}
            borderInlineEnd={border}
            borderInlineEndColor={borderColor}
          >
            <Flex justify="center">{bottomTeamName}</Flex>
          </Td>
          {gameScoreDataList.map((data) => {
            return (
              <Td key={data.inning} px={1}>
                <Flex justify="center">{data.bottomTeamPoint}</Flex>
              </Td>
            );
          })}
          <Td
            px={1}
            borderInlineStart={border}
            borderInlineStartColor={borderColor}
          >
            <Flex justify="center" textAlign="center">
              {bottomTeamAllScore}
            </Flex>
          </Td>
        </Tr>
      </Tbody>
    </Table>
  );
});
