import { Input, Table, Tbody, Td, Th, Tr } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { TableBox } from "../../../atoms/TableBox";
import { MiddleTitle } from "../../../atoms/title/MiddleTitle";

interface ScoreInputProps {
  score: { teamA: number[]; teamB: number[] };
  onScoreChange: (newScore: { teamA: number[]; teamB: number[] }) => void;
}

const ScoreInput: React.FC<ScoreInputProps> = ({ score, onScoreChange }) => {
  const handleChange = (
    team: "teamA" | "teamB",
    inning: number,
    value: string
  ) => {
    const numValue = parseInt(value, 10);
    const newScore = { ...score, [team]: [...score[team]] };
    newScore[team][inning] = isNaN(numValue) ? 0 : numValue;
    onScoreChange(newScore);
  };

  useEffect(() => {
    console.log("Current score state: ", score);
  }, [score]);

  const innings = Array.from({ length: 9 }, (_, i) => i + 1); // 1〜9イニング

  return (
    <TableBox>
      <MiddleTitle>試合スコア入力</MiddleTitle>
      <Table mt={1}>
        <Tbody>
          <Tr>
            <Th></Th>
            {innings.map((inning) => (
              <Th key={inning} px={2} mx={2} textAlign="center">{inning}</Th>
            ))}
          </Tr>
          <Tr>
            <Td py={4} whiteSpace="nowrap">チームA</Td>
            {innings.map((inning, index) => (
              <Td key={inning} px={2} mx={2} py={4}>
                <Input
                  type="number"
                  value={
                    score.teamA[index] !== undefined
                      ? score.teamA[index].toString()
                      : ""
                  }
                  onChange={(e) => handleChange("teamA", index, e.target.value)}
                  p={0}
                  minW={8}
                  textAlign="center"
                />
              </Td>
            ))}
          </Tr>
          <Tr>
            <Td py={4} whiteSpace="nowrap">チームB</Td>
            {innings.map((inning, index) => (
              <Td key={inning} px={2} mx={2} py={4}>
                <Input
                  type="number"
                  value={
                    score.teamB[index] !== undefined
                      ? score.teamB[index].toString()
                      : ""
                  }
                  onChange={(e) => handleChange("teamB", index, e.target.value)}
                  p={0}
                  minW={8}
                  textAlign="center"
                />
              </Td>
            ))}
          </Tr>
        </Tbody>
      </Table>
    </TableBox>
  );
};

export default ScoreInput;
