import { createColumnHelper } from "@tanstack/react-table";
import { PlayerStatsType } from "../components/types/PlayerStatsType";

const columnHelper = createColumnHelper<PlayerStatsType>();

export const PlayerStatsColumns = [
  columnHelper.accessor("playerNo", {
    cell: (info) => info.getValue(),
    header: "背番号",
  }),
  columnHelper.accessor("fullName", {
    cell: (info) => info.getValue(),
    header: "氏名",
  }),
  columnHelper.accessor("battingAverage", {
    cell: (info) => info.getValue(),
    header: "打率",
  }),
  columnHelper.accessor("plateAppearances", {
    cell: (info) => info.getValue(),
    header: "打席数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("atBats", {
    cell: (info) => info.getValue(),
    header: "打数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("hits", {
    cell: (info) => info.getValue(),
    header: "安打",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("homeRuns", {
    cell: (info) => info.getValue(),
    header: "本塁打",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("RBIs", {
    cell: (info) => info.getValue(),
    header: "打点",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("runsScored", {
    cell: (info) => info.getValue(),
    header: "得点",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("stolenBases", {
    cell: (info) => info.getValue(),
    header: "盗塁",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("onBasePercentage", {
    cell: (info) => info.getValue(),
    header: "出塁率",
  }),
  columnHelper.accessor("sluggingPercentage", {
    cell: (info) => info.getValue(),
    header: "長打率",
  }),
  columnHelper.accessor("OPS", {
    cell: (info) => info.getValue(),
    header: "OPS",
  }),
  columnHelper.accessor("doubles", {
    cell: (info) => info.getValue(),
    header: "二塁打",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("triples", {
    cell: (info) => info.getValue(),
    header: "三塁打",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("totalBases", {
    cell: (info) => info.getValue(),
    header: "塁打数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("strikeouts", {
    cell: (info) => info.getValue(),
    header: "三振",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("walks", {
    cell: (info) => info.getValue(),
    header: "四球",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("sacrificeHits", {
    cell: (info) => info.getValue(),
    header: "犠打",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("sacrificeFlies", {
    cell: (info) => info.getValue(),
    header: "犠飛",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("doublePlays", {
    cell: (info) => info.getValue(),
    header: "併殺打",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("errors", {
    cell: (info) => info.getValue(),
    header: "失策",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("fielding", {
    cell: (info) => info.getValue(),
    header: "守備率",
  }),
];
