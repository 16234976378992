import { GameScoreInningType } from "../components/types/GameScoreInningType";

export const gameScoreDataList1: GameScoreInningType[] = [
  {
    inning: "1",
    topTeamPoint: 1,
    bottomTeamPoint: 1,
    isCrossMark: false,
  },
  {
    inning: "2",
    topTeamPoint: 2,
    bottomTeamPoint: 1,
    isCrossMark: false,
  },
  {
    inning: "3",
    topTeamPoint: 0,
    bottomTeamPoint: 0,
    isCrossMark: false,
  },
  {
    inning: "4",
    topTeamPoint: 1,
    bottomTeamPoint: 0,
    isCrossMark: false,
  },
  {
    inning: "5",
    topTeamPoint: 1,
    bottomTeamPoint: 1,
    isCrossMark: false,
  },
  {
    inning: "6",
    topTeamPoint: 0,
    bottomTeamPoint: 1,
    isCrossMark: false,
  },
  {
    inning: "7",
    topTeamPoint: 0,
    bottomTeamPoint: 1,
    isCrossMark: false,
  },
];

export const gameScoreDataList2: GameScoreInningType[] = [
  {
    inning: "1",
    topTeamPoint: 0,
    bottomTeamPoint: 0,
    isCrossMark: false,
  },
  {
    inning: "2",
    topTeamPoint: 0,
    bottomTeamPoint: 0,
    isCrossMark: false,
  },
  {
    inning: "3",
    topTeamPoint: 0,
    bottomTeamPoint: 1,
    isCrossMark: false,
  },
  {
    inning: "4",
    topTeamPoint: 3,
    bottomTeamPoint: 1,
    isCrossMark: false,
  },
  {
    inning: "5",
    topTeamPoint: 0,
    bottomTeamPoint: 0,
    isCrossMark: false,
  },
  {
    inning: "6",
    topTeamPoint: 0,
    bottomTeamPoint: 0,
    isCrossMark: false,
  },
  {
    inning: "7",
    topTeamPoint: 0,
    bottomTeamPoint: 0,
    isCrossMark: false,
  },
];

export const gameScoreDataList3: GameScoreInningType[] = [
  {
    inning: "1",
    topTeamPoint: 0,
    bottomTeamPoint: 0,
    isCrossMark: false,
  },
  {
    inning: "2",
    topTeamPoint: 0,
    bottomTeamPoint: 1,
    isCrossMark: false,
  },
  {
    inning: "3",
    topTeamPoint: 0,
    bottomTeamPoint: 2,
    isCrossMark: false,
  },
  {
    inning: "4",
    topTeamPoint: 0,
    bottomTeamPoint: 3,
    isCrossMark: false,
  },
  {
    inning: "5",
    topTeamPoint: 1,
    bottomTeamPoint: 8,
    isCrossMark: false,
  },
];
