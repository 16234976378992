import { createColumnHelper } from "@tanstack/react-table";
import { PlayerPitchStatsType } from "../components/types/PlayerPitchStatsType";

const columnHelper = createColumnHelper<PlayerPitchStatsType>();

export const PlayerPitchStatsColumns = [
  columnHelper.accessor("playerNo", {
    cell: (info: any) => info.getValue(),
    header: "背番号",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("fullName", {
    cell: (info: any) => info.getValue(),
    header: "氏名",
  }),
  columnHelper.accessor("games", {
    cell: (info: any) => info.getValue(),
    header: "試合数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("wins", {
    cell: (info: any) => info.getValue(),
    header: "勝利数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("saves", {
    cell: (info: any) => info.getValue(),
    header: "セーブ数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("losses", {
    cell: (info: any) => info.getValue(),
    header: "敗北数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("winRate", {
    cell: (info: any) => info.getValue(),
    header: "勝率",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("ERA", {
    cell: (info: any) => info.getValue(),
    header: "防御率",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("inningsPitched", {
    cell: (info: any) => info.getValue(),
    header: "投球回数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("runsAllowed", {
    cell: (info: any) => info.getValue(),
    header: "失点数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("earnedRuns", {
    cell: (info: any) => info.getValue(),
    header: "自責点数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("completeGames", {
    cell: (info: any) => info.getValue(),
    header: "完投数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("shutouts", {
    cell: (info: any) => info.getValue(),
    header: "完封数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("hitsAllowed", {
    cell: (info: any) => info.getValue(),
    header: "被安打数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("homeRunsAllowed", {
    cell: (info: any) => info.getValue(),
    header: "被本塁打数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("strikeouts", {
    cell: (info: any) => info.getValue(),
    header: "奪三振数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("walks", {
    cell: (info: any) => info.getValue(),
    header: "与四死球数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("balks", {
    cell: (info: any) => info.getValue(),
    header: "ボーク数",
    meta: {
      isNumeric: true,
    },
  }),
  columnHelper.accessor("wildPitches", {
    cell: (info: any) => info.getValue(),
    header: "暴投数",
    meta: {
      isNumeric: true,
    },
  }),
];

