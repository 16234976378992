import { createColumnHelper } from "@tanstack/react-table";
import { PlayerPitchStatsOfGameType } from "../components/types/PlayerPitchStatsOfGameType";

const columnHelper = createColumnHelper<PlayerPitchStatsOfGameType>();

export const PlayerPitchStatsOfGameColumns = [
  columnHelper.accessor("playerNo", {
      cell: (info: any) => info.getValue(),
      header: "背番号",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("playerName", {
      cell: (info: any) => info.getValue(),
      header: "選手名",
  }),
  columnHelper.accessor("result", {
      cell: (info: any) => info.getValue(),
      header: "勝敗",
  }),
  columnHelper.accessor("inningsPitched", {
      cell: (info: any) => info.getValue(),
      header: "投球回",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("runsAllowed", {
      cell: (info: any) => info.getValue(),
      header: "失点",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("earnedRuns", {
      cell: (info: any) => info.getValue(),
      header: "自責点",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("hitsAllowed", {
      cell: (info: any) => info.getValue(),
      header: "被安打数",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("homeRunsAllowed", {
      cell: (info: any) => info.getValue(),
      header: "被本塁打数",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("strikeouts", {
      cell: (info: any) => info.getValue(),
      header: "奪三振数",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("walksAllowed", {
      cell: (info: any) => info.getValue(),
      header: "与四死球数",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("balks", {
      cell: (info: any) => info.getValue(),
      header: "ボーク数",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("wildPitches", {
      cell: (info: any) => info.getValue(),
      header: "暴投数",
      meta: {
          isNumeric: true,
      },
  }),
  columnHelper.accessor("appearanceOrder", {
      cell: (info: any) => info.getValue(),
      header: "登板順",
      meta: {
          isNumeric: true,
      },
  }),
];