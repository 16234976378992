import { memo } from "react";
import { Route, Routes } from "react-router-dom";
import { Login } from "../pages/Login";
import { HomeRoutes } from "./HomeRoutes";
import { HeaderLayout } from "../templates/HeaderLayout";
export const Router = memo(() => {
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      {HomeRoutes.map((route) => (
        <Route path={"/home" + route.path} element={
          <HeaderLayout>{route.children}</HeaderLayout>} />
      ))}
    </Routes>
  );
});
