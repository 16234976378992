import { PlayerType } from "../components/types/PlayerType";

export const PlayerData: PlayerType[] = [
  {
    id: "1",
    playerNo: 1,
    lastName: "山田",
    firstName: "太郎",
    fullName: "山田 太郎",
    age: 28,
    position: "P",
    throw: "右",
    hit: "右",
    joinedAt: "2023",
    role: "選手",
  },
  {
    id: "2",
    playerNo: 10,
    lastName: "高橋",
    firstName: "次郎",
    fullName: "高橋 次郎",
    age: 30,
    position: "2B",
    throw: "右",
    hit: "左",
    joinedAt: "2023",
    role: "選手",
  },
  {
    id: "3",
    playerNo: 18,
    lastName: "一二三",
    firstName: "晋平太",
    fullName: "一二三 晋平太",
    age: 28,
    position: "3B",
    throw: "右",
    hit: "右",
    joinedAt: "2023",
    role: "選手",
  },
];
